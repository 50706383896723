import { createWebHistory, createRouter } from "vue-router";
import Home from "@/pages/Home.vue";
import About from "@/pages/About.vue";
import Madhura from "@/pages/Madhura.vue";
import Cyber from "@/pages/Cyber.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/madhura",
    name: "Madhura",
    component: Madhura,
  },
  {
    path: "/cyber",
    name: "Cyber",
    component: Cyber,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;