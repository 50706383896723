<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="display-2 font-weight-bolder mb-4">Our Company</h1>
        <p class="lead mb-4 mb-lg-5">
          Madhu builders has been providing customers for over a decade to fullfil
          their goals in terms of equity for future, retirement plans,
          strategies for kids education and settlement, higher return on
          investments etc., by providing excellent real estate opportunities.
          <br />Explore our ventures below to serve your need.
        </p>
      </div>
      <div class="col-md-12">
        <h2 class="mb-4">Location</h2>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60926.22912812761!2d78.52029541662408!3d17.369058020150714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0000000000000000%3A0x3a409a42d692658f!2sMadhu+Builders!5e0!3m2!1sen!2sca!4v1462148634699"
          width="100%"
          height="320"
          frameborder="0"
          style="border: 0"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div class="row mb-3 pb-3">
      <div class="col-12 border-bottom mt-3 pt-3">
          <h2 class="mb-4">Contacts</h2>
      </div>
      <div class="col-12 col-md-6 mb-4 mb-lg-0">
        <div class="card border-0 bg-white text-center p-1">
          <div class="card-header bg-white border-0 pb-0">
            <div class="icon icon-lg icon-primary mb-4">
              <span class="fas fa-money-bill-wave"></span>
            </div>
            <h2 class="h3 text-dark m-0 border-bottom mb-3">Managing Director</h2>
          </div>
          <div class="card-body m-0 p-0">
            <p>D.Madhu</p>
            <a class="mb-link" href="mailto:madhu@madhubuilders.com">madhu@madhubuilders.com</a><br>
            <a class="mb-link" href="tel:+919000000068">+91 9000000068</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mb-4 mb-lg-0">
        <div class="card border-0 bg-white text-center p-1">
          <div class="card-header bg-white border-0 pb-0">
            <div class="icon icon-lg icon-primary mb-4">
              <span class="fas fa-map-marked-alt"></span>
            </div>
            <h2 class="h3 text-dark m-0 border-bottom mb-3">Asst. Manager</h2>
          </div>
          <div class="card-body m-0 p-0">
            <p>D.Ravinder</p>
            <a class="mb-link" href="mailto:ravinder@madhubuilders.com">ravinder@madhubuilders.com</a><br>
            <a class="mb-link" href="tel:+919030987589">+91 9030987589</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>