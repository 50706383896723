<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="display-2 font-weight-bolder mb-4">Madhura Avenue</h1>
        <p class="lead mb-3 mt-0">Live the green life at madhura avenue</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <iframe key="6p7wVYlgoca6" ref="mapFrame"
          width="100%"
          height="419"
          frameborder="0"
          style="border: 1px solid green"
          allowfullscreen
        ></iframe>
      </div>
      <div class="col-md-6">
        <p class="lead mb-1">Layout</p>
        <div class="card border-light">
          <div class="images">
            <img
              class="img-fluid img-thumbnail"
              src="../assets/images/madhura/layout.png"
              alt="layout"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <p class="lead mb-1">Photos</p>
        <div class="card border-light">
          <div class="mb-img-container">
            <img
              alt="madhura"
              v-on:click="showImage"
              class="img-fluid img-thumbnail"
              src="../assets/images/madhura/2.jpg"
            />
            <img
              alt="madhura"
              v-on:click="showImage"
              class="img-fluid img-thumbnail"
              src="../assets/images/madhura/3.jpg"
            />
            <img
              alt="madhura"
              v-on:click="showImage"
              class="img-fluid img-thumbnail"
              src="../assets/images/madhura/4.jpg"
            />
            <img
              alt="madhura"
              v-on:click="showImage"
              class="img-fluid img-thumbnail"
              src="../assets/images/madhura/5.jpg"
            />
            <img
              alt="madhura"
              v-on:click="showImage"
              class="img-fluid img-thumbnail"
              src="../assets/images/madhura/6.jpg"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4 pt-4">
      <div class="col-md-12">
        <h2 class="mb-4">Avenue highlights</h2>
        <ul class="text-start">
          <li>100 feet road approach from Sagar High Way</li>
          <li>Just 0.5 km from Sagar High Way</li>
          <li>Near Bharath Institue of Engineering And Technology</li>
          <li>2 Km from Outer Ring Road</li>
          <li>
            Near by TCS, CogniZant, Tata Aero Space, Hardware Park, Engineering
            colleges, Hospitals and BDL
          </li>
          <li>15 min Drive from L.B Nagar Ring Road</li>
          <li>30 min Drive from Hitech City</li>
          <li>20 min Drive from Shamshabad Airport</li>
          <li>Near Residential Houses</li>
        </ul>
      </div>
      <div class="col-md-12">
        <h2 class="mb-4">Location</h2>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3674.903321092895!2d78.57087264855981!3d17.20782243812848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcba684128b887f%3A0x9ecb152b8c9bf269!2sMadhura+Avenue%2C+Kongara+Kalan+Rd%2C+Telangana+501510!5e0!3m2!1sen!2sin!4v1462158069446"
          width="100%"
          height="320"
          frameborder="0"
          style="border: 0"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div id="imageViewer" ref="imageViewer" class="modal" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header p-0">
            <button
              style="
                position: absolute;
                z-index: 999;
                right: 20px;
                top: 20px;
                text-shadow: 2px 2px white;
              "
              type="button"
              class="btn-close pull-right"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body m-0 p-0">
            <div class="card border-light p-2">
              <img id="imgFull" ref="imgFull" class="img-fluid" src="" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Modal from "bootstrap/js/dist/modal";
export default {
  computed: {
    url() {
      return `https://p99.in/map/6p7wVYlgoca6`;
    },
  },
  mounted() {
    window.sessionStorage.clear();
    this.$refs.mapFrame.src = this.url;
  },
  methods: {
    showImage(evt) {
      var imagSrc = evt.target.currentSrc;
      this.$refs.imgFull.src = imagSrc;
      var myModalEl = new Modal(this.$refs.imageViewer);
      myModalEl.show();
    },
  },
};
</script>