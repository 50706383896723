<template>
  <div class="container mt-3">
    <div class="row">
      <div class="col-md-12 text-center">
        <h1 class="display-2 font-weight-bolder mb-4">Our Ventures</h1>
        <p class="lead mb-4 mb-lg-5">
          Madhu builders has been providing customers for over a decade to fullfil
          their goals in terms of equity for future, retirement plans,
          strategies for kids education and settlement, higher return on
          investments etc., by providing excellent real estate opportunities.
          <br />Explore our ventures below to serve your need.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-6 mb-4 mb-lg-0">
        <div class="card border-0 bg-white text-center p-1">
          <div class="card-header bg-white border-0 pb-0">
            <div class="icon icon-lg icon-primary mb-4">
              <i class="bi bi-geo-alt mb-color" style="font-size: 64px"></i>
            </div>
            <h2 class="h3 text-dark m-0">Madhura Avenue</h2>
          </div>
          <div class="card-body">
            <p>
              @Mangalpally Patelguda<br />
              Ibrrahimpatnam(M)
            </p>
            <router-link to="/madhura">More info</router-link>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-6 mb-4 mb-lg-0">
        <div class="card border-0 bg-white text-center p-1">
          <div class="card-header bg-white border-0 pb-0">
            <div class="icon icon-lg icon-primary mb-4">
              <i class="bi bi-globe2 mb-color" style="font-size: 64px"></i>
            </div>
            <h2 class="h3 text-dark m-0">Cyber Avenue</h2>
          </div>
          <div class="card-body">
            <p>
              @Mangalpally<br />
              Ibrrahimpatnam(M)
            </p>
            <router-link to="/cyber">More info</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>