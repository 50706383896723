<template>
  <div>
    <header class="border-bottom">
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
          <a class="navbar-brand m-auto" href="/">
            <img src="./assets/logo.png" alt="Madhu Builders" height="64" />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 text-start">
              <li class="nav-item">
                <router-link class="nav-link" to="/"
                  ><i class="bi bi-house-door mb-icon"></i> Home</router-link
                >
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="bi bi-map mb-icon"></i> Ventures
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <router-link class="dropdown-item" to="/madhura"
                      ><i class="bi bi-geo-alt mb-icon"></i> Madhura
                      Avenue</router-link
                    >
                  </li>
                  <li>
                    <router-link class="dropdown-item" to="/cyber"
                      ><i class="bi bi-globe2 mb-icon"></i> Cyber
                      Avenue</router-link
                    >
                  </li>
                  <!-- <li><hr class="dropdown-divider" /></li>
                  <li>
                    <a class="dropdown-item" href="#">Something else here</a>
                  </li> -->
                </ul>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/about"
                  ><i class="bi bi-info-circle mb-icon"></i>About</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
    <main class="container-fluid mt-3">
      <router-view :key="$route.fullPath" />
    </main>
    <footer class="pt-3 border-top bg-light">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md">
            <img class="mb-1" src="./assets/logo.png" alt="" height="96" />
            <small class="d-block mb-3 text-muted mt-2 mb-4"
              >Copyright © Madhu Builders 2000 - {{ new Date().getFullYear() }}.
              All rights reserved.</small
            >
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import "bootstrap/dist/js/bootstrap";
export default {
  name: "App",
  watch: {
    $route: {
      // with immediate handler gets called on first mount aswell
      immediate: true,
    },
  },
};
</script>

<style>
#app {
  font-family: "Spartan", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
}
</style>
